<template>
	<form-menu
			:center="true"
			:center_btn="true"
			:listDeploy="listDeploy"
			height="380px"
			width="400px"
			:ridDivision="true"
			:dialog.sync="dialog"
			rightbtn_text="确定"
			leftbtn_text="取消"
			@resetForm="dialog = false"
			@validateSave="validateSave"
			ref="formRef"
			title="库存"/>
</template>

<script>
import formMenu from "@/components/form-menu/form-menu.vue";

export default {
	components: {
		formMenu
	},
	props: {
		storePriceDialog: Boolean,
		code: String
	},
	data() {
		return {
			dialog: false,
			listDeploy: {
				row: [
					{
						cols: 12,
						subassembly: "VTextField",
						property: 'avl',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "今日剩余库存"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'todayPickup',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								label: "今日领取数量",
								readonly: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'pickup',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								label: "领取总数",
								readonly: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'todayCheck',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								label: "今日核销数量",
								readonly: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'check',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								label: "核销总数",
								readonly: true
							}
						}
					}
				]
			}
		}
	},
	methods: {
		validateSave(item) {
			if (item.done) {
				item.form.couponcode = this.code;
				this.axios.post('/api/coupon/save_coupon_avl', item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("保存成功");
						this.dialog = false
					} else {
						this.$refs.formRef.load = false;
					}
				});
			}
		}
	},
	watch: {
		dialog: {
			handler(v) {
				if (!v) {
					this.$emit("closeStorePriceDialog");
				}
			}
		},
		code: {
			handler(v) {
				this.axios.post('/api/coupon/load_coupon_avl', {searchkey: v}).then(res => {
					this.listDeploy.row[0].default = res.data.avl
					this.listDeploy.row[1].default = res.data.todayPickup
					this.listDeploy.row[2].default = res.data.pickup
					this.listDeploy.row[3].default = res.data.todayCheck
					this.listDeploy.row[4].default = res.data.check
					this.dialog = true
				})
			},
			immediate: true
		}
	},
}
</script>
