<template>
	<div>
		<common-table
				:itemScope="['lsell', 'storePrice', 'lreal']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				pageTitle="优惠券小类"
				:listDeploy="listDeploy"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:lreal="{ item }">
				{{ item.lreal ? '是' : '否' }}
			</template>

			<template v-slot:storePrice="{ item }">
				<v-icon class="iconfont ml-2" color="primary" size="24" @click="storePrice(item.code)">iconfont icon-chakan-fill
				</v-icon>
			</template>
		</common-table>
		<template v-if="storePriceDialog">
			<store-price
					:code="typeCode"
					:storePriceDialog.sync="storePriceDialog"
					@closeStorePriceDialog="storePriceDialog=false"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/couponType.js';
import storePrice from "./couponTypeStorePrice.vue";
import commonData from "@/api/common-data.js";

export default {
	mixins: [commonCurd],
	components: {storePrice},
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			typeCode: '',
			storePriceDialog: false,
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			leftConfig: [{type: 'input', label: '输入小类代码/名称', property: 'searchkey'}],
			headers: [
				{text: '小类代码', value: 'code'},
				{text: '小类名称', value: 'description'},
				{text: '排序号', value: 'seq'},
				{text: '库存', value: 'storePrice'},
				{text: '预订状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "优惠券小类代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "优惠券小类名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'price',
						connector: {
							props: {
								required: true,
								type: 'number',
								label: "优惠券价格"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					}
				]
			}
		}
	},
	methods: {
		// 库存&价格
		storePrice(item) {
			this.storePriceDialog = true;
			this.typeCode = item;
		},
		createdExtend() {
			this.fixedParams.group = this.$route.query.value;
		},
		beforeSave(form) {
			form.groupid = this.$route.query.value;
		}
	}
}
</script>

<style scoped lang="scss">

</style>
